
import { computed, defineComponent } from 'vue'

const tokenListHasLogoPng = [
  'wbtc',
  'vrt',
  'usdt',
  'usdc',
  'uni',
  'sos',
  'eth',
  'dai',
  'ar',
  'ardrive',
  'xyz',
  'glmr',
  'dodo',
  'mask',
  'bank',
  'cfx',
  'zlk',
  't4ever',
  'fox',
  'bnb',
  'lat',
  'xsgd',
  'fra',
  'arg',
  'acnh',
  'ans',
  'raven',
  'u',
  'stamp',
  'drop',
  'sarco',
  'mapo'
]
const tokenListHasLogoSvg = [
  'map',
  'aocred',
  'halo',
  '0rbt',
  'trunk',
  'nexp',
  'exp(ario)',
  'trunk(ao)'
]

export default defineComponent({
  props: {
    symbol: {
      type: String,
      default: ''
    },
    chainType: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const logoName = computed(() => {
      if (props.symbol.toLowerCase() === 'ar' && props.chainType.toLowerCase() === 'aostest') {
        return 'war.png'
      } else if (props.symbol.toLowerCase() === 'halo' && props.chainType.toLowerCase() === 'psntest') {
        return 'psn-halo.svg'
      } else if (props.symbol && tokenListHasLogoPng.some(name => name === props.symbol.toLowerCase())) {
        return `${props.symbol.toLowerCase()}.png`
      } else if (props.symbol && tokenListHasLogoSvg.some(name => name === props.symbol.toLowerCase())) {
        return `${props.symbol.toLowerCase()}.svg`
      } else {
        return 'default.svg'
      }
    })
    return {
      logoName
    }
  }
})
